var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12-mobile is-12-tablet is-6-desktop is-4-widescreen"},[_c('p',{staticClass:"has-text-weight-semibold is-size-5"},[_vm._v(" "+_vm._s(_vm.$t("listeUtilisateurInvite"))+" ")])]),_c('div',{staticClass:"column is-12-mobile is-12-tablet is-2-desktop is-3-widescreen"},[_c('b-tooltip',{attrs:{"label":"L'utilisateur 'en séance' pratique ses exercices dans l'établissement du professionnel et en sa présence.","position":"is-bottom","size":"is-large","multilined":""}},[_c('b-icon',{staticClass:"color-enseance",attrs:{"pack":"fas","icon":"circle"}}),_vm._v(" "+_vm._s(_vm.$t("pratiqueAuCabinet"))+" ")],1)],1),_c('div',{staticClass:"column is-12-mobile is-12-tablet is-2-desktop is-3-widescreen"},[_c('b-tooltip',{attrs:{"label":"L'utilisateur peut pratiquer ses exercices 'à distance', au-delà de votre établissement.","position":"is-bottom","size":"is-large","multilined":""}},[_c('b-icon',{staticClass:"color-adistance",attrs:{"pack":"fas","icon":"circle"}}),_vm._v(" "+_vm._s(_vm.$t("pratiqueSousLicence"))+" ")],1)],1),_c('div',{staticClass:"column is-12-mobile is-12-tablet is-2-desktop is-2-widescreen"},[_c('b-tooltip',{attrs:{"label":"Un utilisateur 'à distance', dont l'accès est expiré, peut être re-basculer en statut utilisateur 'en séance'.","position":"is-bottom","size":"is-large","multilined":""}},[_c('b-icon',{attrs:{"pack":"fas","icon":"circle","type":"is-warning"}}),_vm._v(" "+_vm._s(_vm.$t("licenceExpiree"))+" ")],1)],1)]),(_vm.listeUser.length)?_c('div',{staticClass:"tableSuivi"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"custom-table"},[_vm._m(0),_vm._l((_vm.listeUser),function(user){return [_c('tr',{key:user.id,class:{ 'selected-user': _vm.selectedUser === user }},[_c('td',{class:{
                'bfp-done': user.bfp_result,
                'bfp-not-done': !user.bfp_result,
                'bfp-critical': user.bfp_result,
              }},[_c('div',{staticClass:"user-info"},[_c('strong',[_vm._v("Nom: ")]),_vm._v(_vm._s(user.nom)+" ")]),_c('div',{staticClass:"user-info"},[_c('strong',[_vm._v("Prénom: ")]),_vm._v(_vm._s(user.prenom)+" ")]),_c('div',{staticClass:"user-info"},[_c('strong',[_vm._v("Mail: ")]),_vm._v(_vm._s(_vm.checkEmailFormat(user.mail) ? " " + user.mail : "")+" ")]),(user.bfp_result)?_c('div',{staticClass:"notification-circle"}):_vm._e()]),_c('td',{staticClass:"has-text-centered tdVerticalAlign"},[_c('div',{staticClass:"user-creation-date"},[_vm._v(" "+_vm._s(new Date(user.createdAt).toLocaleDateString())+" ")])]),_c('td',{staticClass:"has-text-centered tdVerticalAlign"},[(user.derniere_connexion)?_c('div',{staticClass:"user-creation-date"},[_vm._v(" "+_vm._s(new Date(user.derniere_connexion).toLocaleDateString())+" ")]):_c('div',{staticClass:"user-creation-date-attente"},[_c('i',{staticClass:"fas fa-clock"}),_vm._v(" En attente de connexion ")])]),_c('td',{staticClass:"has-text-centered tdVerticalAlign"},[(
                  user !== undefined &&
                  user.dureeAcces &&
                  user.typePratique === 'licence'
                )?_c('span',{staticClass:"user-access-duration"},[_vm._v(" "+_vm._s(user === undefined ? "" : user.dureeAcces == 1000 ? "Illimité" : user.dureeAcces + " mois")+" ")]):_vm._e()]),_c('td',{staticClass:"has-text-centered tdVerticalAlign"},[(
                  user !== undefined &&
                  user.typePratique === _vm.textLicence &&
                  user.accesActif
                )?_c('div',{staticClass:"user-distance"},[_c('i',{staticClass:"fas fa-circle color-adistance"}),_c('span',[_vm._v(" À distance")])]):(
                  user !== undefined &&
                  user.typePratique === _vm.textLicence &&
                  !user.accesActif
                )?_c('div',{staticClass:"user-nonactif"},[_c('i',{staticClass:"fas fa-circle text-warning"}),_c('span',[_vm._v(" Non actif")])]):_c('div',{staticClass:"user-seance"},[_c('i',{staticClass:"fas fa-circle color-enseance"}),_c('span',[_vm._v(" En séance")])])]),_c('td',{staticClass:"has-text-centered table-button tdVerticalAlign"},[(user !== undefined && user.seance_group)?_c('div',[_c('b-tooltip',{attrs:{"label":"L'utilisateur est associé à une séance de groupe, pour le modifier vous devez le dissocier de la séance de groupe","position":"is-left"}},[_c('img',{staticClass:"icon-tableau",attrs:{"src":require("../../../../assets/img/block.png")}})])],1):_c('a',{on:{"click":function($event){return _vm.openCustomizeUser(user)}}},[_vm._m(1,true)])]),_c('td',{staticClass:"has-text-centered table-button tdVerticalAlign"},[_c('a',{on:{"click":function($event){return _vm.openSuivi(user)}}},[(
                    user !== undefined &&
                    user.typePratique !== 'cabinet' &&
                    (user.acces_donnees_therapeute === undefined ||
                      !user.acces_donnees_therapeute)
                  )?_c('button',{staticClass:"buttonModifUserDisabled"},[_vm._v(" Suivi non disponible "),_c('i',{staticClass:"fas fa-ban"})]):_c('button',{staticClass:"buttonModifUser"},[_vm._v(" Suivi des exercices "),_c('i',{staticClass:"fas fa-chart-line is-margin-l-10 is-margin-r-10 has-text-white"})])])]),_c('td',{staticClass:"has-text-centered tdVerticalAlign"},[_c('button',{staticClass:"buttonModifUser",on:{"click":function($event){_vm.isCardRedirectFront = true;
                  _vm.userSelected = user;}}},[_vm._v(" Espace activités patient "),_c('i',{staticClass:"fas fa-arrow-right"})])])]),(
              _vm.selectedUser === user && _vm.selectedUserForCustomization !== user
            )?_c('tr',{key:user.id + 'suivi',ref:"divBfp",refInFor:true},[_c('td',{staticStyle:{"padding":"25px"},attrs:{"colspan":"11"}},[(!_vm.displaySuiviData)?_c('div',{staticClass:"loader-detail-relecture"}):_c('relectureUser',{key:_vm.keyRelecture,attrs:{"filesDones":_vm.suiviData,"selectedUser":_vm.selectedUser},on:{"closeModal":function($event){_vm.selectedUser = null}}})],1)]):_vm._e(),(
              _vm.selectedUserForCustomization === user && _vm.selectedUser !== user
            )?_c('tr',{key:user.id + 'custom'},[_c('td',{staticStyle:{"padding":"25px"},attrs:{"colspan":"11"}},[_c('div',[_c('PersonnalisationAcces',{attrs:{"selectedUser":_vm.selectedUserForCustomization},on:{"refreshListUser":_vm.handleGetMyUsers}})],1)])]):_vm._e()]})],2)])]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("aucunUtilisateur"))+" ")]),_c('b-modal',{staticStyle:{"background-color":"transparent !important"},attrs:{"width":1200,"active":_vm.isCardRedirectFront},on:{"update:active":function($event){_vm.isCardRedirectFront=$event},"close":function($event){_vm.isCardRedirectFront = false;
      _vm.userSelected = null;}}},[(_vm.userSelected)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("redirectionProVersCabinet"))+" "+_vm._s(_vm.userSelected.mail)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("fermetureSessionPro"))+" ")]),_c('div',[_c('div',{staticClass:"divImageRedirection"},[_c('img',{attrs:{"src":"https://www.symbiocenter.com/assets/img/icone_capteur_003_140918.png"}})]),_c('div',[_c('div',{staticClass:"texteRouge"},[_vm._v(_vm._s(_vm.$t("sessionCabinetLigne1")))]),_c('div',[_vm._v(_vm._s(_vm.$t("sessionCabinetLigne2")))]),_c('div',[_vm._v(_vm._s(_vm.$t("sessionCabinetLigne3")))]),_c('div',[_vm._v(_vm._s(_vm.$t("sessionCabinetLigne4")))])])]),_c('a',{staticClass:"is-pulled-right button is-cyan",on:{"click":function($event){return _vm.redirectFront(_vm.userSelected)}}},[_vm._v(" "+_vm._s(_vm.$t("confirmer"))+" ")]),_c('a',{staticClass:"is-pulled-right button is-grey is-margin-r-10",on:{"click":function($event){_vm.isCardRedirectFront = false}}},[_vm._v(" "+_vm._s(_vm.$t("annuler"))+" ")]),_c('br')])])]):_vm._e()]),(_vm.PopUpQrCode)?_c('qrCodePopUp',{attrs:{"tokenGenerate":_vm.tokenGenerate},on:{"closeModal":function($event){_vm.PopUpQrCode = false}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nom / Prénom / Mail")]),_c('th',[_vm._v("Date de création")]),_c('th',[_vm._v("Dernière connexion")]),_c('th',[_vm._v("Durée d'accès")]),_c('th',[_vm._v("Statut")]),_c('th',[_vm._v("Modifier")]),_c('th',[_vm._v("Suivi")]),_c('th',[_vm._v("Accès")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"buttonModifUser"},[_vm._v(" Modifier "),_c('i',{staticClass:"fas fa-user-edit is-margin-l-10 is-margin-r-10 has-text-white"})])
}]

export { render, staticRenderFns }